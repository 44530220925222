<template>
  <div>
    <div class="" style="width: 100%">
      <img style="display: block;width: 1920px;margin: auto;" src="../../assets/images/djt/gfbs.jpg" alt="">
    </div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c6="" class="page1">
            <h2 _ngcontent-c6="" class="center">攻防比赛</h2>
            <p _ngcontent-c6="" class="em2">关于攻防比赛</p>
            <p _ngcontent-c6="" class="em2">随着《网络安全法》的颁布，网络安全被提到前所未有的高度，守住网络安全底线是企事业单位应尽义务。企业通过攻防演练比赛，能够检验安全防护能力，提升网络安全应急处置水平，暴露问题，降低重大网络安全风险。</p>
            <div _ngcontent-c6="" class="img"><img _ngcontent-c6="" alt="" src="../../assets/images/djt/gfbs5.png"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>

<style lang="less" scoped>
  .bold {
    font-weight: bold;
  }
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
.img {
  text-align: center;
  img {
    width: 100%;
  }
  // width: 100%;
  // display: flex;
  // padding: 10px 0;
  // align-items: center;
  // justify-content: center;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
